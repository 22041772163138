import apiFetch from "../components/util/fetch-utils";
import * as qs from "querystring";


export const MONITORED_URI_DELETED = 'MONITORED_URI_DELETED';
export const FETCH_MONITORED_URI_STARTED = 'FETCH_MONITORED_URI_STARTED';
export const FETCH_MONITORED_URI_SUCCESS = 'FETCH_MONITORED_URI_SUCCESS';
export const FETCH_MONITORED_URI_FAILURE = 'FETCH_MONITORED_URI_FAILURE';
export const MONITORED_URI_REFERENCE_VALUE_UPDATED = 'MONITORED_URI_REFERENCE_VALUE_UPDATED';


function buildUrlForRecommendationExists(workspaceNumber, queryParams) {
    let monitoredUriUrl = '/api/monitored-uris/search/byRecommendationExist';


    monitoredUriUrl += "?" + qs.stringify({
        workspaceNumber: workspaceNumber,
        recommendationsExist: queryParams.recc,
        page: queryParams.page
    });
    return monitoredUriUrl;
}

function buildUrlForGenericSearch(workspaceNumber, queryParams) {
    let monitoredUriUrl = '/api/monitored-uris/search/searchMonitoredUri';


    monitoredUriUrl += "?" + qs.stringify({
        workspaceNumber: workspaceNumber,
        uriContains: queryParams.uriContains,
        uriRegexFilter: queryParams.uriRegexFilter,
        diffs: queryParams.diffs,
        httpStatus: queryParams.httpStatus,
        page: queryParams.page
    });
    return monitoredUriUrl;
}


export function fetchMonitoredUri(querystring, workspaceNumber) {




    return dispatch => {


        dispatch({type: FETCH_MONITORED_URI_STARTED, payload: querystring});


        const queryParams = qs.parse(querystring.replace(/^\?/, ''));
        let monitoredUriUrl;

        if (queryParams.recc === undefined) {
            monitoredUriUrl = buildUrlForGenericSearch(workspaceNumber, queryParams);
        } else {
            monitoredUriUrl = buildUrlForRecommendationExists(workspaceNumber, queryParams)
        }

        // console.log("Fetching ", queryParams, "url: ", monitoredUriUrl)


        apiFetch(monitoredUriUrl)
            .then(response => {

                if (response.status === 200) {
                    response.json().then(responseJson => {
                        let monitoredUri = responseJson._embedded ? responseJson._embedded.monitoredUris : [];
                        dispatch({
                            type: FETCH_MONITORED_URI_SUCCESS, payload: {
                                tableData: monitoredUri,
                                page: responseJson.page
                            }
                        });

                    })
                }
            }).catch((error) => {
            console.error(error);
            dispatch({type: FETCH_MONITORED_URI_FAILURE, error: error})
        });

    }

}

